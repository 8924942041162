import React, { useCallback, useState } from "react";
import { graphql } from "gatsby";
import { Row, Col } from "antd";

import {
  ButtonPrimary,
  ButtonSecondary,
  ContainerContentFixed,
  ContainerCta,
  InternalLink,
  InternalLinkTypes,
  Layout,
  SEO,
  GalleryViewer,
  GalleryImageDisplay,
  Video,
  ContainerContent,
  ContentDisclaimer,
} from "../components";
import Img from "gatsby-image";

export default ({ data }) => {
  const homeDesign = data.allHomeDesignsJson.nodes[0];

  const images = homeDesign.images;
  const [selectedImageIndex, setSelectedImageIndex] = useState();

  const onClickImage = useCallback(
    idx => () => {
      setSelectedImageIndex(idx);
    },
    [setSelectedImageIndex]
  );
  const onModalClose = useCallback(() => {
    setSelectedImageIndex(null);
  }, [setSelectedImageIndex]);
  return (
    <Layout>
      <SEO title={`Home Design`} />
      {/*<BannerPage title={"Home Design"} />*/}
      <ContainerContent style={{ padding: 0 }}>
        <Img
          fluid={homeDesign.albumImagePath.childImageSharp.fluid}
          style={{ marginBottom: "8px", maxHeight: 500 }}
        />
      </ContainerContent>
      <ContainerContentFixed>
        {/*<Row gutter={[8, 8]}>*/}
        {/*<Col md={18} xs={24}>*/}
        {/*<Img*/}
        {/*fluid={images[0].imagePath.childImageSharp.fluid}*/}
        {/*style={{ marginBottom: "8px" }}*/}
        {/*/>*/}
        {homeDesign.youtubeLink && <Video url={homeDesign.youtubeLink} />}
        <Row gutter={[8, 8]}>
          {images.map((img, idx) => (
            <Col
              xxl={8}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={24}
              key={`img-${idx}`}
            >
              <GalleryImageDisplay
                onClick={onClickImage(idx)}
                imageFluid={img.imagePath.childImageSharp.fluid}
              />
              {/*<span>*/}
              {/*  {idx + 1}/{images.length} {img.tags.join(" ").toUpperCase()}*/}
              {/*</span>*/}
            </Col>
          ))}
        </Row>
        <GalleryViewer
          selectedImageIndex={selectedImageIndex}
          images={images}
          onModalClose={onModalClose}
        />
        {/*</Col>*/}
        {/*<Col md={6} xs={24}>*/}
        {/*<div style={{ padding: "2rem 2rem" }}>*/}
        {/*<DisplayStorey storey={homeDesign.info.storey} />*/}
        {/*<br /> <br />*/}
        {/*<p style={{ fontSize: "1.1rem" }}>{homeDesign.description}</p>*/}
        {/*<ButtonPrimary style={{ margin: "4px 0", width: "100%" }}>*/}
        {/*<InternalLink url={InternalLinkTypes.HomeDesigns}>*/}
        {/*See Other Home Designs*/}
        {/*</InternalLink>*/}
        {/*</ButtonPrimary>*/}
        {/*<ButtonSecondary style={{ margin: "4px 0", width: "100%" }}>*/}
        {/*<InternalLink url={InternalLinkTypes.FloorPlans}>*/}
        {/*See Floor Plans*/}
        {/*</InternalLink>*/}
        {/*</ButtonSecondary>*/}
        {/*<InternalLink url={InternalLinkTypes.Disclaimer}>*/}
        {/*See our disclaimer*/}
        {/*</InternalLink>*/}
        {/*</div>*/}
        {/*</Col>*/}
        {/*</Row>*/}
        <section style={{ textAlign: "center" }}>
          <div>
            <ButtonPrimary style={{ margin: "4px", width: "250px" }}>
              <InternalLink url={InternalLinkTypes.HomeDesigns}>
                See Other Home Designs
              </InternalLink>
            </ButtonPrimary>
            <ButtonSecondary style={{ margin: "4px", width: "250px" }}>
              <InternalLink url={InternalLinkTypes.FloorPlans}>
                See Floor Plans
              </InternalLink>
            </ButtonSecondary>
          </div>
        </section>
      </ContainerContentFixed>
      <ContainerContentFixed>
        <ContentDisclaimer />
      </ContainerContentFixed>
      <ContainerCta
        title={"Can't find the home design you like?"}
        subTitle={"Don't worry, we can build one as per your needs"}
        secondaryText={"Customise your own"}
        secondaryLink={InternalLinkTypes.GetStarted}
      />
    </Layout>
  );
};

export const query = graphql`
  query($path: String!) {
    allHomeDesignsJson(filter: { slug: { eq: $path } }) {
      nodes {
        description
        youtubeLink
        info {
          storey
        }
        albumImagePath {
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        images {
          tags
          imagePath {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
